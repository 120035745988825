@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Calistoga&family=Ga+Maamli&family=Playwrite+AT:ital,wght@0,100..400;1,100..400&family=Playwrite+CU:wght@100..400&family=Sevillana&display=swap');

*,html,body{
    padding: 0;
    margin: 0;
    outline: 0;
}

:root {
    --primaryColor: #ddd7b8; /* Hexadecimal */
    /* or */
    --primaryColor: rgb(221, 215, 184); /* RGB */
    --secondaryColor: #e0161e;
}


h1{
    text-decoration: underline;
}

/* App.js */
.app{
    background-color: var(--primaryColor);
}

/* NavBar */
.nav{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--primaryColor);
    padding: 1rem;
    border: 2px solid var(--secondaryColor);
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.nav img{
    width: 150px;
    border-radius: .5rem;
    border: 2px solid black;
}
.nav div{
    display: flex;
    gap: 40px;
    font-weight: bold;
    font-size: 30px;
}
.nav div a{
    text-decoration: underline;
    color: var(--secondaryColor);
    transition: transform .5s;
}
.nav div a:hover{
    transform: scale(1.2);
}
.socials{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 800px) {
    .nav{
        flex-direction: column;
        gap: 50px;
    }
}

/* Intro */
.intro-title{
    display: flex;
    min-height: 100vh;
}
.intro-video-section{
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: center;
}
.intro-video-section video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(4);
}
.intro-video-copy{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    font-size: 10rem;
    line-height: 25rem;
    user-select: none;
    mix-blend-mode: multiply;
    background-color: black;
}
.intro-video-copy h1{
    display: flex;
    justify-content: center;
    color: white;
    font-family: 'Playwrite Cuba', 'Times New Roman', Times, serif;
}
/* Media Queries for responsiveness */
@media (max-width: 1800px) {
    .intro-video-copy{
        font-size: 8rem;
    }
}
@media (max-width: 1200px) {
    .intro-video-copy {
        line-height: 15rem;
    }

    .intro-video-copy h1 {
        font-size: 12vw; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 768px) {
    .intro-title{
        height: 10vh;
    }
    .intro-video-copy {
        font-size: 10rem;
        line-height: 9rem;
    }

}

@media (max-width: 480px) {
    .intro-video-copy {
        font-size: 10rem;
        line-height: 6rem;
    }
}

/* Title */
.title{
    display: flex;
    justify-content: space-between;
    height: 20vh;
}
.videoSection{
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: center;
}
.videoSection video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(4);
}
.videoCopy{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    font-size: 8rem;
    line-height: 15rem;
    user-select: none;
    mix-blend-mode: multiply;
    background-color: black;
}
.videoCopy h2{
    color: white;
    font-family: 'Playwrite Cuba', 'Times New Roman', Times, serif;
}
/* Media Queries for responsiveness */
@media (max-width: 1800px) {
    .videoCopy{
        font-size: 5rem;
    }
}
@media (max-width: 1200px) {
    .videoCopy {
        font-size: 8rem;
        line-height: 12rem;
    }

    .videoCopy h2 {
        font-size: 12vw; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 768px) {
    .title{
        height: 10vh;
    }
    .videoCopy {
        font-size: 6rem;
        line-height: 9rem;
    }

    .videoCopy h2 {
        font-size: 10vw;
    }
}

@media (max-width: 480px) {
    .videoCopy {
        font-size: 10rem;
        line-height: 6rem;
    }

    .videoCopy h1 {
        font-size: 6vw;
    }
}

/* Hero */
.hero{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.heroTxt{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.heroTxt p{
    text-align: center;
    min-width: 300px;
    max-width: 500px;
    line-height: 2rem;
    font-weight: bold;
}
.heroTxt a{
    max-width: 50%;
}
.heroCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Optional: Adds space between the images */
    padding: 1rem;
}

.heroCont img {
    width: 400px; /* Ensure images take up the full cell width */
    height: 400px; /* Maintain aspect ratio */
    border-radius: .5rem;
}
@media (max-width:1200px) {
    .hero{
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .heroCont img{
        width: 100%;
    }
}
@media (max-width: 700px) {
    .heroCont img{
        height: 150px;
    }
}

.call{
    display: block;
    background-color: var(--primaryColor);
    border: 3px solid var(--secondaryColor);
    border-radius: .5rem;
    text-decoration: none;
    font-weight: bold;
    padding: 1rem;
    transition: transform .5s;
    color: black;
    width: 100%;
    text-align: center;
    text-wrap: nowrap;
}
.call:hover{
    transform: scale(1.1);
}

/* Menu */
.menu{
    display: flex;
    justify-content: center;
    padding: 1rem;
    border: 2px solid var(--secondaryColor);
    border-radius: .5rem;
    margin-left: 2rem;
    margin-right: 2rem;
}
.pizzaLink{
    color: var(--secondaryColor);
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
}
.item h3{
    text-decoration: underline;
}
.hours{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('./components/assets/logo.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: .5rem;
}
.overlay{
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: .5rem;
    text-align: center;
    border: 2px solid black;
    padding: 1rem;
    
}
.overlay div{
    margin-top: 2rem;
}
@media (max-width:1200px) {
    .menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .item{
        max-width: 400px;
    }
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.ingredients{
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 1rem;
}
.ingredients h4{
    text-decoration: underline;
}
.ingredients div{
    border-radius: .5rem;
}

/* Reviews */
.reviews{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}
.reviewCardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 1rem;
}
.reviewCard{
    flex: 1;
    border: 2px solid var(--secondaryColor);
    border-radius: .5rem;
    padding: 2rem;
    min-width: 200px;
    max-width: 500px;
    line-height: 2rem;
}
.reviewer{
    font-weight: bold;
}

/* About */
.about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}
.aboutCont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.aboutCont div{
    max-width: 500px;
    font-weight: bold;
    line-height: 2rem;
    padding: 2rem;
}
.aboutCont img{
    max-width: 650px;
    min-height: 500px;
    max-height: 500px;
    border-radius: .5rem;
}
@media (max-width:1000px) {
    .aboutCont{
        flex-direction: column;
    }
    .aboutCont div{
        padding: 0;
    }
}

/* Popular */
.popular{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.best-car{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    min-width: 100px;
    padding: 2rem;
}
.best-item{
    border: 2px solid var(--secondaryColor);
    border-radius: .5rem;
    padding: 1rem;
    text-decoration: underline;
}
.best-item img{
    max-width: 650px;
    max-height: 500px;
    border-radius: .5rem;
}
@media (max-width:700px) {
    .best-car{
        max-width: 300px;
    }
}